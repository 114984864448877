import camelcaseKeys from 'camelcase-keys'
import fetchRetry from 'fetch-retry'

import { Offer, SelectedOffer } from '@common/types'
import { Logger } from '@src/hooks/use-logger'
import {
  getAuthToken,
  getLeadSource,
  getLeadSourceAndLeadCampaignQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl, refiApiUrl } from '../config'

import { getResourceUuids } from './resource-uuid'

export type ExistingLoan = {
  vehicle_year: number
  vehicle_make: string
  vehicle_model: string
  vehicle_trim: string
  bureau: string
  current_loan_amount: number
  remaining_loan_term: number
  current_apr: number
  current_monthly_payment: number
}

export type GetOffersDataResponse = {
  loanApplicationUuid: string
  offers: ReadonlyArray<Offer>
}

export type TopOfferReasonType =
  | 'Combined Savings Ranking'
  | 'Lowest Payment'
  | 'Recommended'

export type UIOffer = {
  id: string
  monthlyPayment: number
  rate: number
  annualPercentageRate?: number
  savingPotential: number
  term: number
  totalPaymentSavings: number
  amountFinanced: number
  isTopOffer: boolean
  topOfferReason: TopOfferReasonType
}

export type UIExistingLoan = {
  carModel: string
  savingPotential: number
  estimatedRate: number
  monthlyPayment: number
  remainingTerm: number
  outstandingAmount: number
  bureau: string
}

export type OfferSelectData = {
  currentLoanInfo: UIExistingLoan
  lowestOption: UIOffer
  flexibleOptions: {
    amountFinanced: number
    options: ReadonlyArray<UIOffer>
  }
}

const getOffersData = async (
  logger: Logger,
): Promise<GetOffersDataResponse> => {
  const fetchWithRetry = fetchRetry(fetch, {
    retries: 7,
    retryDelay: (attempt) => attempt * 1000,
    retryOn: [302],
  })

  const { loanApplicationUuid, offerGroupUuid } = await getResourceUuids()

  // We're still calling the offer group endpoint here to kick off offer generation as it's the only API
  // endpoint able to do so. In the future, we would like this to be handled automatically by the
  // loan workflow instead, and this call can be removed entirely once that is implemented.
  // We poll for offers below as this is typically a long running process.
  await fetch(`${refiApiUrl}/services/v1/offer_group/${offerGroupUuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body: JSON.stringify({
      loanApplicationUuid,
    }),
  }).catch((error) => {
    logger.error('offer generation initiation request error', { error: error })
  })

  const response = await fetchWithRetry(
    `${refiApiUrl}/services/v3/loan_application/${loanApplicationUuid}/offers`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      redirect: 'error',
    },
  )

  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }

  return camelcaseKeys(data, { deep: true })
}

export const getExistingLoan = async (): Promise<ExistingLoan> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${getRequestId()}/existing-loan${getLeadSourceAndLeadCampaignQueryParams()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )

  return response.json()
}

const calculateAmountFinanced = (offer: Offer): number => {
  const totalFees = offer.fees
    .filter((offer) => !offer.name.includes('processing'))
    .reduce((total, offer) => Number(offer.amount) + total, 0)

  return Number(offer.totalAmountApproved) - totalFees
}

type GetOfferSelectDataConfig = {
  logger: Logger
  priorityOffer?: 'topOffer' | 'lowestOption'
}
export const getOfferSelectData = async (
  config: GetOfferSelectDataConfig,
): Promise<OfferSelectData> => {
  const { priorityOffer = 'topOffer' } = config || {}

  const [{ offers = [] }, loan] = await Promise.all([
    getOffersData(config.logger),
    getExistingLoan(),
  ])
  const {
    bureau,
    current_loan_amount,
    remaining_loan_term,
    vehicle_year,
    vehicle_make,
    vehicle_model,
    current_monthly_payment,
    current_apr,
  } = loan

  const getTopOffer = (): Offer => offers.find((x) => x.isTopOffer)
  const getLowerPaymentOffer = (): Offer =>
    offers.reduce(
      (previousOffer, newOffer) => {
        return Number(previousOffer.monthlyPayment) <
          Number(newOffer.monthlyPayment)
          ? previousOffer
          : newOffer
      },
      offers.length > 0 ? offers[0] : null,
    )

  const preferredOffer =
    priorityOffer === 'topOffer' ? getTopOffer() : getLowerPaymentOffer()

  const flexibleOptions = offers.filter(
    (offer) => offer.uuid !== preferredOffer?.uuid,
  )

  const calculateMonthlySavingsPotential = (offer: Offer): number => {
    return Number(current_monthly_payment) - Number(offer.monthlyPayment)
  }

  const mapApiOfferToUIOffer = (offer: Offer): UIOffer => {
    return {
      id: offer.uuid,
      monthlyPayment: offer.monthlyPayment,
      rate: offer.nominalAnnualPercentageRate,
      totalPaymentSavings: offer.totalPaymentSavings,
      savingPotential: calculateMonthlySavingsPotential(offer),
      term: offer.termInMonths,
      amountFinanced: calculateAmountFinanced(offer),
      annualPercentageRate: offer?.combinedAgreementApr,
      isTopOffer: offer.isTopOffer,
      topOfferReason: offer.topOfferReason,
    }
  }

  return {
    currentLoanInfo: {
      carModel: `${vehicle_year} ${vehicle_make} ${vehicle_model}`,
      savingPotential:
        preferredOffer && calculateMonthlySavingsPotential(preferredOffer),
      estimatedRate: current_apr,
      monthlyPayment: current_monthly_payment,
      remainingTerm: remaining_loan_term,
      outstandingAmount: current_loan_amount,
      bureau: bureau.replace('Transunion', 'TransUnion'),
    },
    lowestOption: preferredOffer && mapApiOfferToUIOffer(preferredOffer),
    flexibleOptions: {
      amountFinanced: preferredOffer && calculateAmountFinanced(preferredOffer),
      options: flexibleOptions.map((offer) => mapApiOfferToUIOffer(offer)),
    },
  }
}

export const submitAcceptedOffer = async (offerUuid: string): Promise<void> => {
  await fetch(`${refiApiUrl}/services/v1/offer/${offerUuid}/select`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
      'Referrer-Policy': 'no-referrer-when-downgrade',
    },
  })
}

export const getSelectedOffer = async (): Promise<SelectedOffer> => {
  const requestId = getRequestId()
  const leadSource = getLeadSource()
  const partnerLeadSourceResponse = await fetch(
    `${refiApiUrl}/services/v3/partner_lead_source/source_name/${leadSource}/request_id/${requestId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const { loanApplicationUuid } = await partnerLeadSourceResponse.json()

  const [selectedOfferResponse, existingLoanResponse] = await Promise.all([
    fetch(
      `${refiApiUrl}/services/v2/loan_application/${loanApplicationUuid}/selected_offer`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    ),
    fetch(
      `${refiApiUrl}/services/v1/credit_report_profile/existing_loan/${loanApplicationUuid}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    ),
  ])
  const [selectedOffer, existingLoan] = await Promise.all([
    selectedOfferResponse.json(),
    existingLoanResponse.json(),
  ])

  return {
    ...selectedOffer.selected_offer_information,
    ...selectedOffer.selected_offer_lender,
    ...existingLoan,
    // override offer monthly_payment with total_monthly_payment bc display is always be total
    monthly_payment: selectedOffer.total_monthly_payment,
  }
}

export const getTopOffer = (offers: Array<UIOffer>): UIOffer => {
  return offers.find((offer) => offer.isTopOffer)
}

export const getShortestTermOffer = (offers: Array<UIOffer>): UIOffer => {
  return offers.reduce((bestOffer, currentOffer) => {
    if (
      currentOffer.term < bestOffer.term ||
      (currentOffer.term === bestOffer.term &&
        currentOffer.totalPaymentSavings > bestOffer.totalPaymentSavings)
    ) {
      return currentOffer
    }
    return bestOffer
  }, offers[0])
}

export const getLowestPaymentOffer = (offers: Array<UIOffer>): UIOffer => {
  return offers.reduce((bestOffer, currentOffer) => {
    if (
      currentOffer.monthlyPayment < bestOffer.monthlyPayment ||
      (currentOffer.monthlyPayment === bestOffer.monthlyPayment &&
        currentOffer.totalPaymentSavings > bestOffer.totalPaymentSavings)
    ) {
      return currentOffer
    }
    return bestOffer
  }, offers[0])
}

export const getLowestAPROffer = (offers: Array<UIOffer>): UIOffer => {
  return offers.reduce((bestOffer, currentOffer) => {
    if (
      currentOffer.annualPercentageRate < bestOffer.annualPercentageRate ||
      (currentOffer.annualPercentageRate === bestOffer.annualPercentageRate &&
        currentOffer.totalPaymentSavings > bestOffer.totalPaymentSavings)
    ) {
      return currentOffer
    }
    return bestOffer
  }, offers[0])
}

export type OfferConfiguration = {
  label: string
  offer: UIOffer
}

export const getLabeledOffers = (
  offers: Array<UIOffer>,
): Array<OfferConfiguration> => {
  const topOffer = getTopOffer(offers)

  const otherOffers = offers.filter((offer) => offer.id !== topOffer.id)

  const labeledOffers = [{ label: 'Most savings', offer: topOffer }]

  labeledOffers.push(...otherOffers.map((offer) => ({ label: '', offer })))

  return labeledOffers
}
