import React from 'react'
import { Grid, styled } from '@mui/material'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { useLeadSource } from '@common/lead-source-context'

import { Link } from '../../reskin'

import { CommonFooter } from './variants/common-footer'
import {
  DirectMailFooter,
  directMailFooterRoutes,
} from './variants/direct-mail-footer'

const FooterMain = styled(Grid)(({ theme }) => ({
  maxWidth: `calc(928px + 2 * ${theme.spacing(2)})`,
  fontFamily: 'Poppins',
  fontFeatureSettings: '"ss04" on',
  margin: 'auto',
  alignContent: 'center',
  flexDirection: 'column',

  padding: theme.spacing(11, 2, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(7, 2, 2),
  },
}))

const FooterFineText = styled(Grid)(({ theme }) => ({
  color: theme.extension.colors.text.bodyGray,
  ...theme.extension.typography.paragraphs[14],
  minHeight: '65px',
  '& *': {
    color: theme.extension.colors.text.bodyGray,
    ...theme.extension.typography.paragraphs[14],
  },
  padding: theme.spacing(0),
  flexDirection: 'column',

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(3, 0, 0),
  },
}))

const FooterLinks = styled(Grid)(({ theme }) => ({
  flex: '2 1',
  display: 'flex',
  flexDirection: 'row',
  margin: theme.spacing(4, 0),

  [theme.breakpoints.up('lg')]: {
    justifyContent: 'space-between',
  },
}))

const Copyright = styled(Grid)(({ theme }) => ({
  alignItems: 'flex-start',
  margin: 'auto',
  marginRight: theme.spacing(1),
  flex: '0',
  minWidth: '250px',
}))

const FooterLink = styled(Link)(({ theme }) => ({
  margin: theme.spacing(1, 3, 1, 0),
  textDecoration: 'underline',
  color: theme.extension.colors.text.softBlack,
}))

export const NewFooter = (): JSX.Element => {
  const { pathname } = useRouter()
  const { isDirectMail } = useLeadSource()

  const directMailFooter =
    isDirectMail && directMailFooterRoutes.includes(pathname)

  return (
    // Footer Hero Section
    <FooterMain container direction="column">
      {!directMailFooter && <CommonFooter />}

      <Grid container gap={1} justifyContent="center" mt={5}>
        <Image
          alt="BBB TrustMark"
          src="/assets/TrustMarkBBB.svg"
          width="56"
          height="32"
        />

        <Image
          alt="TrustPilot TrustMark"
          src="/assets/TrustMarkTrustPilot.svg"
          width="60"
          height="32"
        />
      </Grid>

      <FooterFineText item container>
        <FooterLinks item xs container>
          <FooterLink
            href="https://www.caribou.com/electronic-communications-agreement"
            target="_blank"
          >
            Electronic Communications Consent
          </FooterLink>
          <FooterLink
            href="https://www.caribou.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </FooterLink>
          <FooterLink
            href="https://caribou.com/wp-content/uploads/2024/12/Your_California_Privacy_Rights_December_2024.pdf"
            target="_blank"
          >
            Your California Privacy Rights
          </FooterLink>
          <FooterLink
            href="https://www.caribou.com/terms-of-use"
            target="_blank"
          >
            Terms & Conditions
          </FooterLink>
          <FooterLink
            href="https://www.caribou.com/disclosures"
            target="_blank"
          >
            Disclosures
          </FooterLink>
          <FooterLink
            href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/1746612"
            target="_blank"
          >
            NMLS Consumer Access
          </FooterLink>
        </FooterLinks>

        <Copyright item container>
          {`©${new Date().getFullYear()} Caribou Financial, Inc. d/b/a Caribou. Caribou® is a registered trademark of Caribou Financial, Inc. All rights reserved.`}
        </Copyright>
      </FooterFineText>

      {directMailFooter && <DirectMailFooter />}
    </FooterMain>
  )
}
