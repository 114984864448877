import React, { FC } from 'react'

import { Box, Grid } from '@common/react-lib-base'

import { BBB } from '../icon-seals'
import { styled } from '../reskin'

const CenteredGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const BadgeWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  width: '100%',
  padding: theme.spacing(1),
}))

export const Badge: FC = () => (
  <Grid container>
    <BadgeWrapper>
      <CenteredGrid>
        <BBB />
      </CenteredGrid>
    </BadgeWrapper>
  </Grid>
)
