import { FC } from 'react'
import { Box, styled } from '@mui/material'

const PillContainer = styled(Box)(({ theme }) => ({
  ...theme.extension.typography.subheadings['14m'],
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: 16,
  border: `2px solid ${theme.extension.colors.expressive.blueSky[600]}`,
  backgroundColor: theme.extension.colors.surface.lightSky,
  color: theme.extension.colors.text.softBlack,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'uppercase',
  lineHeight: 1,
}))

export type PillProps = {
  children?: React.ReactNode
}

export const Pill: FC<PillProps> = ({ children }) => {
  return <PillContainer>{children}</PillContainer>
}
